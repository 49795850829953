
// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700|Open+Sans:300,400,600,700');

// Variables
@import "variables";
@import "light-theme.module";

// Mixins
@import "../mixins";

// fonts
@import "../global/fonts";

// buttons
@import "../global/buttons";
// notifications
@import "../global/notifications";
@import "../global/animations";

// grid
@import "../global/grid";

// Bootstrap
@import "node_modules/bootstrap/scss/bootstrap.scss";

// Custom CSS
html {
}
body {
  background: var(--primary-background) !important;
  font-family: 'Open Sans', sans-serif;
  color: var(--text-secondary-color);
  font-size: $font-size;
  hr {
    background-color: var(--light-color);
    height: 1px;
    border-style: none;
  }
  a {
    color: var(--primary-color);
    &:hover {
      color: var(--primary-color-hover);
      cursor: pointer;
    }
  }
}
a:hover, a:focus, a:visited {
  text-decoration: none;
  outline: none;
  outline-offset: 0;
}
h1, h2, h3, h4, h5, h6 {
  color: var(--text-primary-color);
}
p {
  color: var(--text-secondary-color);
}

// Color classes
.background-primary {
  background-color: var(--primary-color);
}
.color-primary {
  color: var(--primary-color) !important;
}
.color-danger {
  color: var(--danger-color) !important;
}
.color-success {
  color: var(--success-color) !important;
}
.color-warning {
  color: var(--warning-color);
}
.shop-text-primary {
  color: var(--text-primary-color);
}
.shop-text-secondary {
  color: var(--text-secondary-color);
}
.shop-text-tertiary, .color-gray {
  color: var(--text-tertiary-color);
}

// Font weight
.light-font {
  font-weight: 300 !important;
}
.semibold-font {
  font-weight: 600;
}
%wide-text {
  font-family: 'Open Sans Condensed', sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.wide-text {
  @extend %wide-text;
}

// Headers
.sub-header {
  @extend %wide-text;
  color: var(--text-tertiary-color);
  font-size: $sub-header-size;
  margin-bottom: 0px;
  font-weight: 400;
}
.main-header {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--text-primary-color);
  font-size: $header-font-size;
}
.shop-text {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  color: inherit;
  font-size: $font-size;
  text-transform: none;
  letter-spacing: normal;
}
.section-header {
  font-weight: 600;
}
// shop Card
.shop-card {
  color: var(--text-secondary-color);
  .card-header {
    color: var(--text-tertiary-color);
    background-color: $shop-border-color;
  }
  .card-title {
    font-weight: bold;
    color: var(--text-primary-color);
  }
  .card-subtitle, .card-text {
    color: var(--text-tertiary-color);
  }
  &.disabled-panel {
    // background-color: rgb(251, 251, 251);

    .card-title {
      font-weight: bold;
      color: $super-light-color;
    }
    .card-subtitle, .card-text {
      color: $super-light-color;
    }
    .badge-event-ticket {
      background-color: $super-light-color;
    }
  }
}

// Buttons

.btn-outline-shop {
  color: var(--primary-color);
  background-color: transparent;
  background-image: none;
  border-color: var(--primary-color);
  &:hover {
    color: #fff;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  &:focus {
  }
  &:disabled {
    color: var(--text-tertiary-color);
    border-color: var(--text-tertiary-color);
    color: var(--primary-color-hover);
    border-color: var(--primary-color-hover);
    cursor: default;
    background-color: transparent;
  }
}
.cart-link {
  border: none;
  background: none;
  padding: 0px;
  color: var(--primary-color) !important;

  -webkit-appearance: none;
   -moz-appearance:   none;
   appearance:        none;
  &:hover {
    color: var(--primary-color-hover) !important;
    cursor: pointer;
  }
  &:disabled {
    color: var(--primary-color-hover) !important;
    cursor: default;
  }
  &:focus {
    outline: 0;
  }
}

.ticket-ribbon {
  color: white;
  font-size: 1rem;
  max-width: 70%;
  position: absolute;
  left: 0px;
  top: 7px;
  width: auto;
  margin-left: -6px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: var(--primary-color);
  z-index: 100;
  padding: 0.2rem 0.7rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  &:before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -10;
    border-right: 3px solid darken($primary-color, 30%);
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid darken($primary-color, 30%);
  }
}

// Panels and Stats
.shop-panel {
  background-color: var(--panel-background);
  border-style: none;
  border-radius: $shop-panel-radius;
  box-shadow: var(--panel-shadow);
  position: relative;
  .body {
    padding: 1rem;
  }
}
.hover-shadow {
  transition: box-shadow 0.25s ease-out, transform 0.25s ease-out;
  &:hover {
    box-shadow: $hover-shadow;
    transform: translateY(-3px);
  }
}
.shop-form-section {
  background-color: var(--panel-background);
  border-radius: $panel-radius;
  padding: 1rem;
}
.shop-panel-overlay {
  @include cover;
  background: rgba(0, 0, 0, 0.85);
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.shop-quick-stat {
}
.shop-panel-divider {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}
.shop-panel-divider > span {
  position: relative;
  display: inline-block;
}
.shop-panel-divider > span:before,
.shop-panel-divider > span:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 9999px;
    height: 1px;
    background: $shop-border-color;
}
.shop-panel-divider > span:before {
    right: 100%;
    margin-right: 15px;
}
.shop-panel-divider > span:after {
    left: 100%;
    margin-left: 15px;
}

// Animations
// Fade & Move
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease;
}
.fade-enter, .fade-leave-active {
  opacity: 0
}
.cards-move {
  transition: transform 1s;
}
.cards-enter-active, .cards-leave-active {
  transition: all .3s;
}
.list-complete-leave-active {
  position: absolute;
}
.cards-enter, .cards-leave-to /* .list-leave-active for <2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}
.child-view {
  // width: inherit;
  // transition: all .5s cubic-bezier(.55,0,.1,1);
}
.slide-left-enter, .slide-right-leave-active {
  position: absolute;
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}
.slide-left-leave-active, .slide-right-enter {
  position: absolute;
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}

[v-cloak] {
  display: none;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  position: absolute;
  transition: opacity 0.5s;
  opacity: 0;
}
.fade-move {
  transition: all ease-out 0.5s;
}
// Fast Fade
.fast-fade-enter {
  opacity: 0;
}
.fast-fade-enter-active {
  transition: opacity 0.1s;
}
.fast-fade-leave-active {
  transition: opacity 0.1s;
  opacity: 0;
}
// Collapse
.collapse-enter-active, .collapse-leave-active {
    transition: height 300ms;
    overflow: hidden;
}

// Link
.shop-link {
  color: var(--text-tertiary-color);
  cursor: pointer;
}
.shop-link:hover {
  color: var(--text-secondary-color);
  cursor: pointer;
}
.primary-link {
  color: var(--primary-color);
  cursor: pointer;
  &:hover {
    color: var(--primary-color-hover);
    cursor: pointer;
  }
}

.shop-form {
  // INPUT
  input:not(.shop-clear), select, textarea {
    display: block; //check
    width: 100%; //check
    padding: 0.375rem 0.75rem; //check
    font-size: $font-size; //check
    line-height: 1.5; //check
    &.large {
      padding: 0.5rem 1rem; //check
      font-size: 1.5rem; //check
      line-height: 1.5; //check
    }
    color: var(--text-secondary-color);
    background-color: var(--input-background);
    background-clip: padding-box;
    border: 1px solid rgba(180, 180, 180, 0);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px var(--input-background) inset;
    }
  }
  textarea {
    min-height: 2.5rem;
  }
  textarea::-ms-expand, input::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  textarea:focus ,input:focus, select:focus {
    border-style: solid;
    border-width: 1px;
    border-color: var(--primary-color);
    color: var(--text-primary-color);
    outline: 0;
  }
  textarea::placeholder, input::placeholder {
    color: var(--text-tertiary-color);
    opacity: 1;
  }
  input::-webkit-input-placeholder {
    color: var(--text-tertiary-color);
    opacity: 1;
  }
  input::-moz-placeholder {
    color: var(--text-tertiary-color);
    opacity: 1;
  }
  input:-ms-input-placeholder {
    color: var(--text-tertiary-color);
    opacity: 1;
  }

  // SELECT
  select:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
  }
  // LABEL
  label:not(.shop-clear) {
    font-weight: normal;
    text-transform: uppercase;
    color: var(--text-tertiary-color);
    font-size: $sub-header-size;
  }
  // Form Error Handling
  .shop-form-error {
    input,textarea {
      border-color: var(--danger-color);
    }
    .form-error-text {
      color: var(--danger-color);
      // font-weight: 300;
      font-size: $sub-header-size;
    }
  }
  .shop-form-text {
    color: var(--text-tertiary-color);
    // font-weight: 300;
    font-size: $sub-header-size;
  }
}
.form-error {
  input,textarea {
    background-color: var(--danger-color-overlay) !important;
    border-color: var(--danger-color) !important;
  }
}
.form-error-text {
  color: var(--danger-color);
  font-weight: normal;
  font-size: $sub-header-size;
}

.inline-input {
  display: inline-block; //check
  width: 100%; //check
  padding: 0.3rem 0rem; //check
  font-size: $font-size; //check
  line-height: 1.5; //check
  color: var(--text-secondary-color);
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid rgba(180, 180, 180, 0);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, padding 0.15s, background-color 0.15s;
  cursor: pointer;
  &:focus {
    cursor: text;
    background-color: transparent;
    color: var(--text-secondary-color);
    outline: 0;
  }
  &::placeholder {
    color: var(--text-tertiary-color);
    opacity: 1;
  }
  &:-webkit-autofill {
  }
}
.btn-inline-submit {
  font-size: 0.75rem;
  border-radius: 20%/50%;
  padding: 0rem 0.7rem;
  background-color: #ffffff;
  color: var(--primary-color);
  border: none;
  -webkit-box-shadow: 0 0 10px var(--primary-background);
  &:hover {
    cursor: pointer;
    background-color: darken(#ffffff, 2%);
  }
}
// shop-gallery
.shop-gallery-image {
  width: 100%;
  padding-top: 100%;
}
.shop-gallery {
  border-radius: 10px;
  overflow: hidden;
}

// Events
.shop-event-cover {
  @include aspect-ratio(16, 9);
}
.shop-organizer-cover {
  height: 250px;
  width: 100%;
}
.shop-event-prev {
  background-color: var(--panel-background);
  border-radius: $shop-panel-radius;
  overflow: hidden;
  padding-left: 0px;
  padding-right: 0px;
  box-shadow: var(--panel-shadow);
}
.shop-event-title {
  font-weight: bold;
  color: var(--text-primary-color);
  margin-bottom: 0.1rem;
  margin-top: 0px;
  hyphens: auto;
}
.shop-event-subtitle {
  font-weight: 400;
  color: var(--text-secondary-color);
  margin-bottom: 0rem;
}
.shop-event-text {
  font-weight: 400;
  color: var(--text-secondary-color);
}
.shop-event-collection {
}
.event-teaser {
 color: var(--text-tertiary-color);
}
.badge-event-ticket {
  background-color: var(--text-secondary-color);
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
}

.shop-event-dummy {
  position: relative;
  overflow: hidden;

  .image-dummy {
    background-color: var(--light-color);
  }
  .body {
    position: relative;
  }
  .calendar-dummy {
    height: 50px;
    width: 50px;
    border-radius: 10px;
    background-color: var(--light-color);
    position: absolute;
    top: 16px;
    right: 15px;
  }
  .title-dummy {
    height: 28px;
    background-color: var(--light-color);
    margin-right: 60px;
    margin-bottom: 8px;
  }
  .subtitle-dummy {
    height: 15px;
    background-color: var(--light-color);
    margin-right: 80px;
  }
  .text-line-dummy {
    height: 15px;
    background-color: var(--light-color);
    margin-bottom: 12px;
  }
}

/* NAVBAR */
.shop-nav-icon {
  padding: 8px 8px;
}
.shop-nav {
  background-color: white;
  box-shadow: var(--panel-shadow);
  border-radius: $shop-panel-radius;
  padding: 0.4rem 1rem;
  .nav-links {
  }
  .nav-link {
    color: var(--text-tertiary-color);
    background-color: transparent;
    cursor: pointer;
    font-weight: 400;
    text-transform: uppercase;
    padding: 8px 0px;
    &.active {
      color: var(--text-secondary-color);
      font-weight: 600;
      border: 0px solid $secondary-color;
      // border-bottom-width: 2px;
    }
  }
}
a.inline-nav-link {
  margin-left: 15px;
  color: var(--primary-color) !important;
  background-color: transparent;
  &.active {
    font-weight: bold;
  }
  &:hover {
    color: var(--primary-color-hover);
  }
}

// cart specific
table.shop-cart-table {
  width: 100%;
  border-collapse: collapse;
  td {
    border-top: 1px solid $shop-border-color;
  }
  td, th {
    padding: 0.5rem;
    vertical-align: top;
  }
  tfoot {
    border-top: 1px solid $shop-border-color;
    td {
      padding: 0.3rem;
      padding-right: 0.75rem;
      font-size: 0.75rem;
      color: var(--text-tertiary-color);
      border-style: none;
    }
    tr:first-child {
      td {
        padding-top: 0.75rem;
      }
    }
  }
}
a.remove-voucher {
  color: var(--text-tertiary-color);
  text-decoration: underline;
  &:hover {
    color: var(--text-secondary-color);
  }
}
#btn-checkout {
  margin-right: 0.75rem;
}
input.voucher-input {
  display: block; //check
  width: 100%; //check
  padding: 0.3rem 0.75rem; //check
  font-size: 0.75rem; //check
  line-height: 1.5; //check
  color: var(--text-tertiary-color);
  background-color: transparent;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-style: none;
  text-align: right;
  &::placeholder {
    color: var(--text-tertiary-color);
    opacity: 1;
    text-decoration: underline;
  }
  &:focus {
    color: var(--text-secondary-color);
    outline: 0;
    text-decoration: none;
  }
}
.voucher-error {
  color: var(--primary-color);
  font-size: 0.75rem;
  padding: 0.3rem;
  margin-right: 0.75rem;
  text-align: right;
}

// Status Page
table.ticket-table {
  border-collapse: collapse;
  width: 100%;
  td, th {
    color: inherit;
    text-align: left;
    padding: 8px;
  }
  td {
    border-top: 1px solid rgba(184, 184, 184, 0.2);
  }
}

input.customer-email {
  text-align: center;
  padding: 0.375rem 0.75rem; //check
  font-size: $font-size; //check
  line-height: 1.5; //check
  color: var(--primary-color);
  background-color: transparent;
  background-clip: padding-box;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border: 1px solid var(--primary-color);
  transition: border-color 0.15s ease-in-out, color 0.15s ease-in-out;
  &::placeholder {
    color: var(--primary-color);
    opacity: 1;
  }
  &:focus {
    outline: 0;
  }
  &:disabled {
    &::placeholder {
      color: var(--primary-color-hover);
    }
    color: var(--primary-color-hover);
    border-color: var(--primary-color-hover);
  }
}

#customer-form {
  input, .stripe-input, select {
    display: block; //check
    width: 100%; //check
    padding: 0.375rem 0.75rem; //check
    font-size: 1rem; //check
    line-height: 1.5; //check
    color: var(--text-secondary-color);
    background-color: var(--input-background);
    background-clip: padding-box;
    border: 1px solid rgba(180, 180, 180, 0);
    border-radius: 0.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &::placeholder, &::-webkit-input-placeholder, &::-moz-placeholder, &:-ms-input-placeholder, .stripe-input-placeholder {
      color: var(--text-tertiary-color);
      opacity: 1;
    }
    &:focus {
      border-style: solid;
      border-width: 1px;
      border-color: var(--primary-color);
      color: var(--text-secondary-color);
      outline: 0;
    }
    &:disabled {
      background-color: var(--hover-background);
      color: var(--text-tertiary-color);
    }
  }
  // SELECT
  select:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
  }
  img.greyscale-icon {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    opacity: 0.8;
    transition: 0.3s filter ease, 0.3s opacity ease;
  }
  img.colorize-icon {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
    opacity: 1;
  }
}

.organizer-logo-wrapper {
  //background-color: $primary-color;
  @include gradient-background(90deg, var(--primary-color), $secondary-color);
  border-radius: 50%;
  height: 4rem;
  width: 4rem;
  padding: 2px;

  .organizer-logo-inner {
    background-color: var(--panel-background);
    border-radius: 50%;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}

// PLaceholder animated gradient
.placeholder-gradient {
  @include animated-background(#dbdbdb, #b9b9b9, 2s)
}
