// Elements
$primary-color: #74bedd;
$secondary-color: #848FBE;
$success-color: #1BC98E;
$info-color: #1BE1D3;
$danger-color: #E64759;
$danger-color-overlay: rgba($danger-color, 0.25);
$warning-color: #e2a74f;
$light-color: #bdbdbd;
$super-light-color: rgb(215, 215, 215);

// panel
$panel-shadow: 0px 5px 100px 0px rgba(221, 221, 221, 0.6);
$panel-hover-shadow: 0px 10px 50px 0px rgba(184, 184, 184, 0.6);

// Text
$text-primary: #1c1c1c;
$text-secondary: #505050;
$text-tertiary: #909090;

// Background
$background-primary: #ffffff;
$panel-background: #ffffff;
$input-background: #f0f0f0;

// Table
$table-border-color: $input-background;

:root {
  // global
  --primary-color: #{$primary-color};
  --primary-color-hover: #{lighten($primary-color, 5%)};
  --secondary-color: #{$secondary-color};
  --success-color: #{$success-color};
  --success-color-light: #{lighten($success-color, 5%)};
  --info-color: #{$info-color};
  --danger-color: #{$danger-color};
  --danger-color-overlay: #{$danger-color-overlay};
  --warning-color: #{$warning-color};
  --light-color: #{$light-color};
  --dark-color: #{darken($light-color, 5%)};
  --danger-color-light: #{lighten($danger-color, 5%)};
  // text
  --text-primary-color: #{$text-primary};
  --text-secondary-color: #{$text-secondary};
  --text-tertiary-color: #{$text-tertiary};
  // background
  --primary-background: #{$background-primary};
  --panel-background: #{$panel-background};
  --panel-background-dark: #{darken($panel-background, 5%)};
  --input-background: #{$input-background};
  --hover-background: #{darken($input-background, 5%)};
  // borders
  --table-border-color: #{$table-border-color};
  --light-border-color: #{lighten($light-color, 5%)};
  // shadows
  --panel-shadow: #{$panel-shadow};
  --panel-hover-shadow: #{$panel-hover-shadow};
}