// Font Sizes
$font-size: 1rem;
$header-font-size: 2.5rem;
$sub-header-size: 0.75rem;

// Radius
$panel-radius: 0.5rem;
$shop-panel-radius: 1.2rem;


