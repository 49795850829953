// Elements
// $primary-color: #d1525d;
$primary-color: #74bedd;
$secondary-color: #848FBE;
// $primary-color: #f57d62;
$success-color: #1BC98E;
$info-color: #1BE1D3;
$danger-color: #E64759;
$warning-color: #e2a74f;
$light-color: #bdbdbd;
$super-light-color: rgb(215, 215, 215);

// Text
$text-primary: #1c1c1c;
$text-secondary: #505050;
$text-tertiary: #909090;

$sub-header-size: 0.75rem;
$font-family: 'Open Sans', sans-serif;
$font-weight: normal;

// panel
$panel-shadow: 0px 5px 100px 0px rgba(221, 221, 221, 0.6);
$hover-shadow: 0px 10px 50px 0px rgba(184, 184, 184, 0.6);

// Radius
$panel-radius: 0.5rem;
$shop-panel-radius: 1.2rem;

$masonry-gutter: 15px;

// Background
$background-primary: #ffffff;
$panel-color: #ffffff;
$input-background: #f0f0f0;

// Table
$table-border: $input-background;

$shop-border-color: rgb(224, 224, 224);

// Breakpoints
$break-small: 576px;
$break-medium: 768px;
$break-large: 992px;
$break-xlarge: 1200px;
