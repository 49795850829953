@import 'variables';
@import '../mixins';

.shop-notification-group {
  position: fixed;
  right: 16px;
  z-index: 1000;
}

.shop-notification {
  position: relative;
  display: block;
  width: 300px;
  border-radius: $panel-radius;
  color: var(--text-secondary-color);
  background-color: var(--panel-background);
  box-shadow: var(--panel-shadow);
  padding: 1rem;
  padding-right: 3rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
}
.notification-status {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  @include gradient-background(90deg, var(--primary-color), var(--secondary-color));
  &.success {
    @include gradient-background(90deg, var(--success-color-light), var(--success-color));
  }
  &.error {
    @include gradient-background(90deg, var(--danger-color-light), var(--danger-color));
  }
}
.error-notification {
  color: #721c24;
  background-color: rgba(249, 215, 218, 0.9);
  border-color: #f5c6cb;
}
.success-notification {
  color: #155724;
  background-color: rgba(212, 237, 218, 0.9);
  border-color: #c3e6cb;
}
.hide-notification-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  color: inherit;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  opacity: .5;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  cursor: pointer;
  &:hover, &:focus {
    outline: 0;
    color: #000;
    text-decoration: none;
    opacity: .75;
  }
}