@import "variables";

// USAGE: https://github.com/alexiscolin/vue-colcade

// max 3 columns
.masonry-grid-3 {
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;

   .grid-col {
     -webkit-box-flex: 1;
     -webkit-flex-grow: 1;
     -ms-flex-positive: 1;
     flex-grow: 1;
     padding-right: $masonry-gutter;

     @media screen and (min-width: $break-small) {
       width: 100%;
     }
     @media screen and (min-width: $break-large) {
       width: 50%;
     }
     @media screen and (min-width: $break-xlarge) {
       width: 33.33%;
     }
   }

   .grid-col--1 {
     padding-right: 0px;
   }

   /* 1 column by default, hide columns 2 & 3 */
   .grid-col--2, .grid-col--3 { display: none }

   /* 2 columns at medium size */
   @media ( min-width: $break-large ) {
     .grid-col--2 {
       display: block;
       padding-right: 0px;
     }
     .grid-col--1 {
       padding-right: $masonry-gutter;
     }
   }

   /* 3 columns at large size */
   @media ( min-width: $break-xlarge ) {
     .grid-col--3 {
       display: block;
       padding-right: 0px;
     }
     .grid-col--1, .grid-col--2 {
       padding-right: $masonry-gutter;
     }
   }

   .grid-item {
     margin-bottom: $masonry-gutter;
   }
 }

// max 2 columns
.masonry-grid-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  .grid-col {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-right: $masonry-gutter;

    @media screen and (min-width: $break-small) {
      width: 100%;
    }
    @media screen and (min-width: $break-xlarge) {
      width: 50%
    }
  }

  .grid-col--1 {
    padding-right: 0px;
  }

  /* 1 column by default, hide columns 2 & 3 */
  .grid-col--2 { display: none }

  /* 2 columns at large size */
  @media ( min-width: $break-xlarge ) {
    .grid-col--2 {
      display: block;
      padding-right: 0px;
    }
    .grid-col--1 {
      padding-right: $masonry-gutter;
    }
  }

  .grid-item {
    margin-bottom: $masonry-gutter;
  }
}



