

$slide-animation-duration: .2s;

// name: slide-left / slide-right
.slide-right-leave-to,
.slide-left-enter {
  // right offset position
  opacity: 0;
  transform: translateX(60%);
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-leave-active,
.slide-left-enter-active {
  transition: opacity ease $slide-animation-duration, transform ease $slide-animation-duration;
}
.slide-left-leave,
.slide-right-leave,
.slide-right-enter-to,
.slide-left-enter-to {
  // target position
  opacity: 1;
  transform: translateX(0);
}
// leave active
.slide-left-leave-active,
.slide-right-leave-active {
  position: absolute;
}

.slide-right-enter,
.slide-left-leave-to {
  // left offset position
  opacity: 0;
  transform: translateX(-60%);
}

.slide-right-move,
.slide-left-move {
  transition: transform 1s;
}

// fade
$fade-animation-duration: .5s;

.fade-left-leave-to,
.fade-left-enter {
  //position: absolute;
  opacity: 0;
  transform: translateX(80%);
}
.fade-left-leave,
.fade-left-enter-to {
  //position: absolute;
  opacity: 1;
  transform: translateX(0);
}
.fade-left-move {
  transition: transform $fade-animation-duration;
}

.fade-left-enter-active,
.fade-left-leave-active {
  transition: opacity ease calc(#{$fade-animation-duration} + 0.1s), transform ease calc(#{$fade-animation-duration} + 0.1s);
}
.fade-left-leave-active {
  position: absolute;
}

// collection items
.collection-fade-enter {
  opacity: 0;
  transform: translateY(5px);
}
.collection-fade-leave-to {
  opacity: 0;
}
.collection-fade-enter-to, .collection-fade-leave {
  opacity: 1;
  transform: translateY(0);
}
.collection-fade-enter-active, .collection-fade-leave-active {
  transition: opacity .3s, transform .3s;
}

// NEED TO ADD POSITION: ABSOLUTE TO THE ELEMENT FOR REMOVAL
.collection-fade-leave-active {
  position: absolute !important;
}
.collection-fade-move {
  transition: transform .3s;
}

// data table
.table-row-leave-to {
  opacity: 0;
}
.table-row-enter-active, .table-row-leave-active {
  transition: opacity .3s, transform .3s;
}
.table-row-leave-active {
  position: absolute !important;
}
.table-row-move {
  transition: transform .3s;
}
.table-row-enter-to, .table-row-leave {
  opacity: 1;
}

