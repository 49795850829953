// fonts
@import "./fonts";
@import "./variables";
@import "../mixins";

// global button

%btn-foundation {
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  padding: 0.375rem 0.75rem;
  overflow: hidden;

  &.small {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
  &.light {
    padding: 0.25rem 0.5rem;
    font-weight: normal;
    text-transform: none;
  }
  // all default solid block colors
  &:not(.inline) {
    color: white;
    background: var(--primary-color);

    // Button States
    &:hover {
      color: white;
      background: var(--primary-color-hover);
    }
    &:disabled {
      background: var(--primary-color-hover);
      &:hover {
        cursor: default;
      }
    }
    &:focus {
      outline: none;
    }
    &:active {
      background: var(--primary-color-hover);
      outline: none;
    }

    // Button variants
    &.facebook {
      background-color: #3b5998;
      &:hover {
        background-color: lighten(#3b5998, 5%);
      }
    }
    &.instagram {
      background-color: #cd486b;
      &:hover {
        background-color: lighten(#cd486b, 5%);
      }
    }
    &.snapchat {
      background-color: #e6e317;
      &:hover {
        background-color: darken(#e6e317, 5%);
      }
    }
    &.twitter {
      background-color: #55acee;
      &:hover {
        background-color: darken(#55acee, 5%);
      }
    }
    &.youtube {
      background-color: #cc3a3f;
      &:hover {
        background-color: darken(#cc3a3f, 5%);
      }
    }
    &.vimeo {
      background-color: #66cc99;
      &:hover {
        background-color: darken(#66cc99, 5%);
      }
    }
    &.light {
      background-color: lightgray;
      color: #1b1e21;
    }
    &.input {
      background-color: var(--input-background);
      color: var(--text-secondary-color);
      &:hover {
        color: var(--text-primary-color);
        background-color: var(--hover-background);
      }
    }
    &.primary-text {
      background-color: var(--input-background);
      color: var(--primary-color);
      &:hover {
        color: var(--primary-color-hover);
      }
    }
    &.primary-overlay {
      background-color: var(--primary-color-overlay);
      color: var(--primary-color);
      &:hover {
        color: var(--primary-color-hover);
      }
    }
    // options
    &.primary {
      background-color: var(--primary-color);
      color: #fff;
      &:hover {
        background-color: var(--primary-color-hover);
      }
    }
    &.gradient {
      @include gradient-background(90deg, var(--primary-color), $secondary-color);
      &:hover {
        @include gradient-background(90deg, var(--primary-color-hover), var(--secondary-color));
      }
    }
  }

  // inline button styling
  &.inline {
    padding: 0;
    display: inline;
    // default
    color: var(--text-tertiary-color);
    &:hover {
      color: var(--text-secondary-color);
    }
    // options
    &.primary {
      color: var(--primary-color);
      &:hover {
        color: var(--primary-color-hover);
      }
    }
  }
}

// Call to action with icon

.call-to-btn {
  @extend %btn-foundation;
  &.success {
    background-color: var(--success-color);
    &:hover {
      background: var(--success-color-light);
    }
  }
  &.failure {
    background-color: var(--danger-color);
    &:hover {
      background: var(--danger-color-light);
    }
  }
  &.disabled {
    &:hover {
      cursor: default;
      background: var(--primary-color-hover);
    }
  }
  &:hover {
    &:after {
      width: 60px;
    }
  }
  &:after {
    content: '';
    position: absolute;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;

    width: 50px;
    height: 200%;
    background: rgba(255,255,255,0.15);
    //z-index: 1;
    right: 0;
    top: 0;
    margin: -5px 0 0 -5px;
    -webkit-transform-origin: 0 0;
    -webkit-transform: rotate(-20deg);
    -moz-transform-origin: 0 0;
    -moz-transform: rotate(-20deg);
    -ms-transform-origin: 0 0;
    -ms-transform: rotate(-20deg);
    transform-origin: 0 0;
    transform: rotate(-20deg);
  }
}

// standard button

.ebdy-btn {
  @extend %btn-foundation;
}

// button group
.ebdy-btn-group {
  .ebdy-btn:not(:first-child) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .ebdy-btn:not(:last-child) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 1px solid var(--light-border-color);
  }
  .ebdy-btn {
    background-color: var(--input-background);
    color: var(--text-tertiary-color);
    &.selected {
      color: var(--primary-color);
      text-shadow: 0 0 5px var(--primary-color-overlay);
    }
    &:hover {
      color: var(--primary-color);
      background-color: var(--input-background);
    }
  }
}

// action list dropdown
.eventbuddy-action-sheet {
  padding: 4px 2px;
  &-button {
    display: block;
    border-top: 1px solid var(--table-border-color);
    padding: 3px 8px;
    color: var(--primary-color);
    &:first-child {
      border-top-color: transparent;
    }
    &:hover {
      color: var(--primary-color-hover);
    };
  }
}