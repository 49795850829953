
@mixin border-radius($radius...) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
// use @include border-radius(10px);

@mixin gradient-background($direction, $colors...) {
  background-color: nth($colors, 1); /* fallback color if gradients are not supported */
  background-image: -webkit-linear-gradient($direction, $colors); /* For Chrome 25 and Safari 6, iOS 6.1, Android 4.3 */
  background-image:    -moz-linear-gradient($direction, $colors); /* For Firefox (3.6 to 15) */
  background-image:      -o-linear-gradient($direction, $colors); /* For old Opera (11.1 to 12.0) */
  background-image:         linear-gradient($direction, $colors); /* Standard syntax; must be last */
}

@mixin gradient-light-top($color) {
  @include gradient-background(to top, $color, lighten($color, 1%));
}
// direction: 135deg or to top left, $colors seperated by ','

@mixin shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
}

@mixin background-image ($path) {
  background-repeat: no-repeat;
  background-image: url('https://placeimg.com/960/540/people');
  background-position: center;
  background-size: cover;
  background-clip: padding-box;
}

/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }

  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }

  $conversion-map: (
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}


@mixin animated-background($color1, $color2, $duration) {
  background: linear-gradient(270deg, $color1 35%, $color2, $color1 65%);
  background-size: 200% 100%;
  position: relative;
  animation-duration: $duration;
  //animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
}

@-webkit-keyframes placeHolderShimmer {
  0%{background-position:200% 50%}
  100%{background-position:0% 50%}
}

%eventbuddy-overlay {
  @supports (backdrop-filter: blur(5px)) {
    background-color: var(--panel-background-overlay);
    backdrop-filter: blur(5px);
  }
  // fallback background
  @supports not (backdrop-filter: blur(5px)) {
    background-color: var(--panel-background);
  }
}

@mixin pulseKeyframes($name, $radius, $color1, $color2) {
  @keyframes #{$name}
  {
    0% {
      box-shadow: 0 0 0 0 $color1;
    }
    100% {
      box-shadow: 0 0 0 $radius $color2;
    }
  }
}
